import * as React from 'react';
import { Box, BoxProps, SxStyleProp, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import { IconValue } from '@deepstream/common';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Stack, StackProps } from '@deepstream/ui-kit/elements/Stack';
import { RequiredAsterisk } from '@deepstream/ui-kit/elements/text/RequiredAsterisk';
import { LabelConfig, useLabelConfig } from '../LabelConfigProvider';

type LabelBaseProps = BoxProps & {
  htmlFor?: string;
  hidden?: boolean;
  width?: any;
};

/**
 * Label component with base styles
 */
const LabelBase = ({ children, sx, ...props }: LabelBaseProps) => (
  <Box as="label" fontSize={1} {...props}>
    <Text sx={{ fontWeight: 500, color: 'lightNavy', ...sx }}>
      {children}
    </Text>
  </Box>
);

const DescriptionBase = styled(Box)`
  font-size: ${props => props.theme.fontSizes[1]}px;
  line-height: 15px;
  color: ${props => props.theme.colors.subtext};
`;

export type ContainerLabelProps = StackProps & {
  /**
   * The form field's name. Only necessary to use if styles have been configured
   * for this field.
   */
  name?: string;
  /**
   * The field's label
   */
  label?: string;
  /**
   * The field's description
   */
  description?: React.ReactNode;
  /**
   * The ID of the description element
   */
  descriptionId?: string;
  /**
   * Whether to display the label or not. Hidden via the `hidden` html attribute.
   */
  hideLabel?: boolean;
  /**
   * Whether or not to show the asterisk next to the label (to represent whether
   * the field is required or not)
   */
  showAsterisk?: boolean;
  /**
   * The id of the form control that the label corresponds to. This allows us to
   * click the label to focus the control.
   */
  htmlFor?: string;
  /**
   * Text or component to be displayed in a tooltip to provide more information about
   * the form field. The tooltip is attached to an info icon displayed next to the label.
   */
  infoTooltip?: React.ReactChild;
  /**
   * Sets the tooltip icon
   */
  tooltipIcon?: IconValue | undefined;
  /**
   * Label styles that overwrite the ones provided by the LabelConfig
   */
  labelStyle?: SxStyleProp;
  /**
   * description styles that overwrite the default styles.
   */
  descriptionStyle?: SxStyleProp;
  /**
   * Children
   */
  children: any;
};

/**
 * Labeled container for form fields. Configured using the `LabelConfigProvider`.
 */
export const FieldContainer = ({
  name,
  label,
  description,
  descriptionId,
  htmlFor,
  hideLabel,
  showAsterisk,
  infoTooltip,
  labelStyle = {},
  descriptionStyle = {},
  children,
  tooltipIcon = 'question-circle',
  ...props
}: ContainerLabelProps) => {
  const {
    variant: labelVariant,
    width: labelContainerWidth,
    gap: gridGap = 1,
    style: labelStyles = {},
  } = useLabelConfig();

  const sx = name
    ? ({ ...labelStyles[name], ...labelStyles.default })
    : labelStyles.default ?? undefined;

  const containerStyle = [LabelConfig.LEFT, LabelConfig.NONE].includes(labelVariant)
    ? { display: 'flex' }
    : undefined;

  const elementWrapperStyle = [LabelConfig.LEFT, LabelConfig.NONE].includes(labelVariant)
    ? { flexGrow: 1 }
    : undefined;

  const labelWrapperStyle = labelVariant === LabelConfig.LEFT
    ? { width: labelContainerWidth, flexShrink: 0 }
    : labelVariant === LabelConfig.NONE
      ? { width: '0px' }
      : undefined;

  hideLabel = hideLabel ?? labelVariant === LabelConfig.NONE;

  return (
    <Stack gap={hideLabel ? 0 : gridGap} {...props} sx={{ ...containerStyle, ...(props?.sx || {}) }}>
      {label && (
        <Box sx={labelWrapperStyle}>
          <LabelBase htmlFor={htmlFor} hidden={hideLabel} sx={{ ...sx, ...labelStyle }}>
            {label}
            {showAsterisk ? <RequiredAsterisk /> : null}
            {infoTooltip ? (
              <Tooltip content={infoTooltip}>
                <Icon icon={tooltipIcon} regular color="lightGray5" ml={1} />
              </Tooltip>
            ) : (
              null
            )}
          </LabelBase>
          {description ? (
            <DescriptionBase id={descriptionId} sx={descriptionStyle}>
              {description}
            </DescriptionBase>
          ) : (
            null
          )}
        </Box>
      )}
      <Box sx={elementWrapperStyle}>
        {children}
      </Box>
    </Stack>
  );
};
