import { isEmpty, some, every } from 'lodash';

export const hasSomeVerifiedMemberships = (user) =>
  !isEmpty(user.companyRoles) && some(user.companyRoles, roles => !roles.hasSentRequest);

export const hasOnlyPendingMemberships = (user) => (
  (!isEmpty(user.companyRoles) && every(user.companyRoles, roles => roles.hasSentRequest)) ||
  !isEmpty(user.requestedRoles) ||
  !isEmpty(user.requestedCompanyRoles)
);

export const hasIncompleteData = user =>
  !user.firstName || !user.lastName || !user.hasAcceptedLatestTermsOfService || !user.preferences?.locale;
