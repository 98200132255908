import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { ensureIntlListFormat } from '@deepstream/ui/polyfill/ensureIntlListFormat';
import { initReactTranslations } from '@deepstream/ui/i18n';
import App from './App';

Sentry.init({
  dsn: process.env.NX_ONBOARDING_SENTRY_DSN,
  environment: process.env.NX_SENTRY_ENV,
  release: `onboarding@${process.env.NX_RELEASE_REV}`,
  // Log errors only from scripts from our domain and
  // ignore 3rd parties scripts like Google Analytics
  // E.g matches https://deepstreamtech.com https://app.deepstreamtech.com https://app.demo.deepstreamtech.com
  // eslint-disable-next-line prefer-regex-literals
  allowUrls: [new RegExp('https?://([a-z0-9]+[.])*deepstreamtech[.]com')],
  ignoreErrors: [
    'Loading chunk',
    'Default values in destructuring declarations',
    'Object Not Found Matching Id',
  ],
});

ensureIntlListFormat()
  .then(initReactTranslations)
  .then(() => {
    ReactDOM.render(<App />, document.getElementById('root'));
  })
  .catch(err => console.log(err));
