import { Form, Formik } from 'formik';
import { useState, useRef, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { withRouter } from 'react-router-dom';
import { Flex, Box } from 'rebass/styled-components';
import styled from 'styled-components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { useMutation } from '@deepstream/ui/useMutation';
import { Loading } from '@deepstream/ui/ui/Loading';
import { UnstyledLink } from '@deepstream/ui/ui/UnstyledLink';
import { RadioFieldBase } from '@deepstream/ui/form/RadioField';

import { getPrimarySubtag } from '@deepstream/utils';
import { useApi } from './ApiProvider';
import Button from './Button';
import Field from './Field';
import { useAuthContext } from './hooks';
import * as Layout from './Layout';
import { Heading } from './text';
import { WizardNavDots } from './WizardNav';
import Checkbox from './Checkbox';

const IncompleteDataStep = {
  LOCALE: 'locale',
  PROFILE: 'profile',
  TERMS: 'terms',
};

const phoneNumberRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

const TermsContainer = styled(Box)`
  > div {
    font-size: 14px;
    counter-reset: h2counter;
  }

  h2 {
    font-family: Roboto Mono;
    font-size: 16px;
    font-weight: 400;
  }

  h2:before {
    counter-increment: h2counter;
    content: counter(h2counter) '. ';
  }

  h2:nth-of-type(-n+9):before {
    content: '0'counter(h2counter) '. ';
  }

  > div > ol {
    padding-left: 0;
  }

  ol ol {
    padding-left: 16px;
  }

  ol {
    counter-reset: item;
  }

  li {
    display: block;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  li:before {
    content: counters(item, ".") ". ";
    counter-increment: item;
  }

`;

const IncompleteProfile = ({ wizardNumberOfSteps }) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { currentUser } = useAuthContext();
  const { t } = useTranslation('onboarding');

  const [updateUserProfile] = useMutation(api.updateUserProfile.bind(api), {
    onSuccess: () => queryClient.invalidateQueries('me'),
  });

  return (
    <Layout.Page title={t('incompleteProfile.addYourDetails')}>
      {wizardNumberOfSteps > 1 && (
        <Flex justifyContent="center" mb="24px">
          <WizardNavDots
            numSteps={wizardNumberOfSteps}
            stepIndex={2}
          />
        </Flex>
      )}
      <Heading style={{ textAlign: 'center', marginBottom: '8px' }}>
        {t('incompleteProfile.addYourDetails')}
      </Heading>
      <Box color="text" fontSize={2} textAlign="center" mb="24px">
        {t('incompleteProfile.tellUsMore')}
      </Box>
      <Formik
        initialValues={{
          firstName: currentUser.firstName || '',
          lastName: currentUser.lastName || '',
          phoneNumber: currentUser.phoneNumber || '',
        }}
        validationSchema={
          yup.object().shape({
            firstName: yup.string().trim().required(t('required', { ns: 'general' })),
            lastName: yup.string().trim().required(t('required', { ns: 'general' })),
            phoneNumber: yup.string().matches(phoneNumberRegExp, t('general.phoneNumberNotValid')),
          })
        }
        onSubmit={async ({ firstName, lastName, phoneNumber }) => {
          await updateUserProfile({
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            phoneNumber: phoneNumber.trim(),
          });
        }}
        render={({ isSubmitting, dirty, isValid }) => (
          <Form>
            <Flex>
              <Field
                name="firstName"
                label={t('firstName', { ns: 'general' })}
                type="text"
                isRequired
                style={{ marginRight: '24px' }}
              />
              <Field name="lastName" label={t('lastName', { ns: 'general' })} type="text" isRequired />
            </Flex>
            <Field
              name="phoneNumber"
              label={t('general.yourDirectPhoneNumber')}
              helperText={t('general.fasterCustomerSupport')}
              type="tel"
            />
            <Layout.Row justify="flex-end" style={{ marginTop: '24px' }}>
              <Button type="submit" disabled={isSubmitting || !dirty || !isValid}>
                {t('general.saveAndContinue')}
              </Button>
            </Layout.Row>
          </Form>
        )}
      />
    </Layout.Page>
  );
};

const IncompleteTerms = ({ wizardNumberOfSteps }) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { t } = useTranslation('onboarding');

  const { data: termsOfService, isSuccess, isLoading } = useQuery(
    ['terms'],
    api.getLatestTermsOfService.bind(api),
  );

  const [acceptTermsOfService] = useMutation(api.acceptTermsOfService.bind(api), {
    onSuccess: () => queryClient.invalidateQueries('me'),
  });

  return (
    <Layout.Page title={t('termsOfService.termsOfService')}>
      {wizardNumberOfSteps > 1 && (
        <Flex justifyContent="center" mb="24px">
          <WizardNavDots
            numSteps={wizardNumberOfSteps}
            stepIndex={1}
          />
        </Flex>
      )}
      <Heading style={{ textAlign: 'center', marginBottom: '8px' }}>
        {t('termsOfService.termsOfService')}
      </Heading>
      <Box color="text" fontSize={2} textAlign="center" mb="16px">
        {t('termsOfService.mustReadAndAgree')}
      </Box>
      <TermsContainer
        mb="16px"
        height="355px"
        overflowY="auto"
        sx={{ border: 'gray' }}
        p="8px 24px"
      >
        {isLoading ? (
          <Loading />
        ) : isSuccess ? (
          // This should be fine because the terms HTML can only be set by us
          <div dangerouslySetInnerHTML={{ __html: termsOfService.html }} />
        ) : (
          null
        )}
      </TermsContainer>
      <Box fontSize={1} color="subtext" mb="16px">
        {t('termsOfService.copyOfTheseTerms')}{' '}
        <UnstyledLink
          href={process.env.NX_TERMS_OF_SERVICE_URL}
          target="_blank"
          style={{ color: 'subtext !important', textDecoration: 'underline' }}
        >
          {process.env.NX_TERMS_OF_SERVICE_URL}
        </UnstyledLink>
      </Box>
      <Formik
        initialValues={{
          hasAgreedToTerms: false,
        }}
        validationSchema={
          yup.object().shape({
            hasAgreedToTerms: yup.boolean().required(t('required', { ns: 'general' }))
              .oneOf([true], t('required', { ns: 'general' })),
          })
        }
        onSubmit={async () => {
          await acceptTermsOfService(termsOfService.version);
        }}
        render={({ isSubmitting, dirty, isValid }) => (
          <Form>
            <Checkbox
              name="hasAgreedToTerms"
              label={t('termsOfService.haveReadAndAgree')}
              disabled={isLoading}
            />
            <Layout.Row justify="flex-end" style={{ marginTop: '24px' }}>
              <Button type="submit" disabled={isLoading || isSubmitting || !dirty || !isValid}>
                {t('continue', { ns: 'general' })}
              </Button>
            </Layout.Row>
          </Form>
        )}
      />
    </Layout.Page>
  );
};

const IncompleteLocale = ({ wizardNumberOfSteps }) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { t } = useTranslation('onboarding');
  const fieldName = 'locale';
  const [locale, setLocale] = useState('');

  const { data: enabledLanguages, isLoading } = useQuery(
    ['languages'],
    api.getEnabledLanguages.bind(api),
  );

  const [updateUserProfile] = useMutation(api.updateUserProfile.bind(api), {
    onSuccess: () => queryClient.invalidateQueries('me'),
  });

  const languageNames = new Intl.DisplayNames(['en'], { type: 'language' });

  return (
    <Layout.Page title={t('adjustLanguage.adjustLanguage')}>
      {wizardNumberOfSteps > 1 && (
        <Flex justifyContent="center" mb="24px">
          <WizardNavDots
            numSteps={wizardNumberOfSteps}
            stepIndex={0}
          />
        </Flex>
      )}
      <Heading style={{ textAlign: 'center', marginBottom: '8px' }}>
        {t('adjustLanguage.adjustLanguage')}
      </Heading>
      <Box color="text" fontSize={2} textAlign="center" mb="16px">
        {t('adjustLanguage.selectPreference')}
      </Box>
      <Formik
        initialValues={{
          locale,
        }}
        validationSchema={
          yup.object().shape({
            locale: yup.string(),
          })
        }
        onSubmit={async ({ locale }) => {
          await updateUserProfile({ preferences: { locale } });
        }}
      >
        {({ isSubmitting, dirty, isValid, setFieldValue }) => (
          <Form>
            <RadioFieldBase
              gap={2}
              name={fieldName}
              required
              options={enabledLanguages ? enabledLanguages.map(language => {
                return {
                  label: languageNames.of(getPrimarySubtag(language.code)),
                  value: language.code,
                };
              }) : []}
              showError
              value={locale}
              onChange={async (value) => {
                setLocale(value);
                setFieldValue('locale', value);
                i18next.changeLanguage(value);
              }}
              hasBoxStyle
            />
            <Layout.Row justify="flex-end" style={{ marginTop: '24px' }}>
              <Button type="submit" disabled={isLoading || isSubmitting || !dirty || !isValid}>
                {t('general.saveAndContinue')}
              </Button>
            </Layout.Row>
          </Form>
        )}
      </Formik>
    </Layout.Page>
  );
};

const getNumberOfSteps = (user) => {
  if (!user.hasAcceptedLatestTermsOfService && (!user.firstName || !user.lastName) && !user.preferences?.locale) {
    return 3;
  }

  if (!user.hasAcceptedLatestTermsOfService && (!user.firstName || !user.lastName)) {
    return 2;
  }

  if (!user.hasAcceptedLatestTermsOfService && !user.preferences?.locale) {
    return 2;
  }

  if ((!user.firstName || !user.lastName) && !user.preferences?.locale) {
    return 2;
  }

  return 1;
};

export const IncompleteDataWizard = withRouter(({ match, history }) => {
  const { currentUser } = useAuthContext();

  const wizardNumberOfSteps = useRef(getNumberOfSteps(currentUser));

  const { stepId } = match.params;

  useEffect(
    () => {
      let redirectStep;

      if (!currentUser.preferences?.locale) {
        redirectStep = IncompleteDataStep.LOCALE;
      } else if (!currentUser.hasAcceptedLatestTermsOfService) {
        redirectStep = IncompleteDataStep.TERMS;
      } else if (!currentUser.firstName || !currentUser.lastName) {
        redirectStep = IncompleteDataStep.PROFILE;
      }

      if (!redirectStep) {
        history.replace('/');
      } else if (redirectStep !== stepId) {
        history.replace(`/incomplete/${redirectStep}`);
      }
    },
    [currentUser, stepId, history],
  );

  return stepId === IncompleteDataStep.TERMS ? (
    <IncompleteTerms wizardNumberOfSteps={wizardNumberOfSteps.current} />
  ) : stepId === IncompleteDataStep.PROFILE ? (
    <IncompleteProfile wizardNumberOfSteps={wizardNumberOfSteps.current} />
  ) : stepId === IncompleteDataStep.LOCALE ? (
    <IncompleteLocale wizardNumberOfSteps={wizardNumberOfSteps.current} />
  ) : (
    null
  );
});
