import { useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import CompanyList from './CompanyList';
import { Heading } from './text';
import * as Layout from './Layout';
import { useApi } from './ApiProvider';

const CompanyVerificationStatus = {
  PENDING_COMPANY_VERIFICATION: 'pendingCompanyVerification',
  PENDING_INTERNAL_VERIFICATION: 'pendingInternalVerification',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  UNKNOWN: 'unknown',
};

const Verification = () => {
  const api = useApi();
  const [companies, setCompanies] = useState([]);
  const { t } = useTranslation('onboarding');

  useEffect(
    () => {
      api.getCompanyVerification().then(setCompanies);
    },
    [api],
  );

  const { accepted, rejected, pending } = groupBy(companies, (company) => {
    switch (company.verificationStatus) {
      case CompanyVerificationStatus.ACCEPTED: return 'accepted';
      case CompanyVerificationStatus.REJECTED: return 'rejected';

      // Is this a _probably_ a rejected join company request?
      case CompanyVerificationStatus.UNKNOWN: return 'unknown';

      default: return 'pending';
    }
  });

  return (
    <Layout.Page wide title={t('verification.myCompanies')}>
      <Heading>
        {t('verification.myCompanies')}
      </Heading>
      <CompanyList type="accepted" companies={accepted} />
      <CompanyList type="pending" companies={pending} />
      <CompanyList type="rejected" companies={rejected} />
    </Layout.Page>
  );
};

export default Verification;
